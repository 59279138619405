import { Button, InputGroup, Text } from '@workos-inc/component-library';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { FileField, FileInputChangeEvent } from 'components/fields';
import { StoreContext } from 'components/store-provider';
import { Article, Title } from 'components/typography';
import { motion } from 'framer-motion';
import { ConnectionFragment } from 'graphql/generated';
import { SSOErrors } from 'interfaces/errors';
import { UpdatableConnectionFields } from 'interfaces/step-props';
import { useRouter } from 'next/router';
import React, {
  ChangeEventHandler,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
} from 'react';
import { Check } from 'react-feather';
import { testConnection } from 'utils/test-connection';

interface ConfigurationCompleteProps {
  completeConnection: () => Promise<void>;
  errorFields?: ErrorFields;
  errors?: SSOErrors;
  isLoading?: boolean;
  providerLabel: string;
  onFileInput: FileInputChangeEvent;
  onInputChange?: ChangeEventHandler<HTMLInputElement>;
  connectionUpdatedFields: UpdatableConnectionFields;
  setConnectionUpdatedFields: Dispatch<
    SetStateAction<UpdatableConnectionFields>
  >;
}

interface ErrorFields {
  [key: string]: {
    label: string;
    step: number;
  };
}

export const ConfigurationComplete: React.FC<
  Readonly<ConfigurationCompleteProps>
> = ({
  completeConnection,
  errorFields,
  errors,
  isLoading,
  providerLabel,
  onInputChange,
  onFileInput,
  connectionUpdatedFields,
  setConnectionUpdatedFields,
}) => {
  const { query, push } = useRouter();
  const setActiveStep = (step: number) => {
    void push(`/sso/${step}`);
  };

  const {
    connection: [connection],
  } = useContext(StoreContext);
  let errorCount = 0;

  if (errors) {
    const keys = Object.keys(errors) as (keyof ConnectionFragment)[];

    errorCount = keys.reduce((count, field) => {
      if (errors?.[field]?.value === connection?.[field]) {
        return ++count;
      }

      return count;
    }, 0);
  }

  const getFieldComponent = (field: string): ReactElement | null => {
    switch (field) {
      case 'oidc_client_id':
        return (
          <div key={field}>
            <Card>
              <InputGroup
                error={errors?.oidc_client_id?.message}
                id="oidc_client_id"
                label={errorFields?.oidc_client_id?.label}
                name="oidc_client_id"
                onChange={onInputChange}
                value={connection?.oidc_client_id ?? undefined}
              />
            </Card>

            <StepButton
              setActiveStep={setActiveStep}
              step={errorFields?.oidc_client_id?.step}
            />
          </div>
        );
      case 'oidc_client_secret':
        return (
          <div key={field}>
            <Card>
              <InputGroup
                error={errors?.oidc_client_secret?.message}
                id="oidc_client_secret"
                label={errorFields?.oidc_client_secret?.label}
                name="oidc_client_secret"
                onChange={onInputChange}
                value={connection?.oidc_client_secret ?? undefined}
              />
            </Card>
            <StepButton
              setActiveStep={setActiveStep}
              step={errorFields?.oidc_client_secret?.step}
            />
          </div>
        );
      case 'oidc_discovery_endpoint':
        return (
          <div key={field}>
            <Card>
              <InputGroup
                error={errors?.oidc_discovery_endpoint?.message}
                id="oidc_discovery_endpoint"
                label={errorFields?.oidc_discovery_endpoint?.label}
                name="oidc_discovery_endpoint"
                onChange={onInputChange}
                value={connection?.oidc_discovery_endpoint ?? undefined}
              />
            </Card>

            <StepButton
              setActiveStep={setActiveStep}
              step={errorFields?.oidc_discovery_endpoint?.step}
            />
          </div>
        );
      case 'oidc_redirect_uri':
        return (
          <div key={field}>
            <Card>
              <InputGroup
                error={errors?.oidc_redirect_uri?.message}
                id="oidc_redirect_uri"
                label={errorFields?.oidc_redirect_uri?.label}
                name="oidc_redirect_uri"
                value={connection?.oidc_redirect_uri ?? undefined}
              />
            </Card>

            <StepButton
              setActiveStep={setActiveStep}
              step={errorFields?.oidc_redirect_uri?.step}
            />
          </div>
        );
      case 'saml_idp_url':
        return (
          <div key={field}>
            <Card>
              <InputGroup
                error={errors?.saml_idp_url?.message}
                id="saml_idp_url"
                label={errorFields?.saml_idp_url?.label}
                name="saml_idp_url"
                onChange={onInputChange}
                value={connection?.saml_idp_url ?? undefined}
              />
            </Card>

            <StepButton
              setActiveStep={setActiveStep}
              step={errorFields?.saml_idp_url?.step}
            />
          </div>
        );
      case 'saml_idp_metadata_url':
        return (
          <div key={field}>
            <Card>
              <InputGroup
                error={errors?.saml_idp_metadata_url?.message}
                id="saml_idp_metadata_url"
                label={errorFields?.saml_idp_metadata_url?.label}
                name="saml_idp_metadata_url"
                onChange={onInputChange}
                value={connection?.saml_idp_metadata_url ?? undefined}
              />
            </Card>

            <StepButton
              setActiveStep={setActiveStep}
              step={errorFields?.saml_idp_metadata_url?.step}
            />
          </div>
        );
      case 'saml_entity_id':
        return (
          <div key={field}>
            <Card>
              <InputGroup
                error={errors?.saml_entity_id?.message}
                id="saml_entity_id"
                label={errorFields?.saml_entity_id?.label}
                name="saml_entity_id"
                onChange={onInputChange}
                value={connection?.saml_entity_id ?? undefined}
              />
            </Card>

            <StepButton
              setActiveStep={setActiveStep}
              step={errorFields?.saml_entity_id?.step}
            />
          </div>
        );
      case 'saml_x509_certs':
        return (
          <div key={field}>
            <Card>
              <FileField
                error={errors?.saml_x509_certs}
                label={errorFields?.saml_x509_certs?.label}
                name="saml_x509_certs"
                onUpload={onFileInput}
                value={connection?.saml_x509_certs?.[0]}
              />
            </Card>
            <StepButton
              setActiveStep={setActiveStep}
              step={errorFields?.saml_x509_certs?.step}
            />
          </div>
        );
      case 'saml_idp_metadata_xml':
        return (
          <div key={field}>
            <Card>
              <FileField
                error={errors?.saml_idp_metadata_xml}
                label={errorFields?.saml_idp_metadata_xml?.label}
                name="saml_idp_metadata_xml"
                onUpload={({ file }) => {
                  setConnectionUpdatedFields({
                    saml_idp_metadata_xml: file.content,
                  });
                }}
                value={connectionUpdatedFields?.saml_idp_metadata_xml}
              />
            </Card>
            <StepButton
              setActiveStep={setActiveStep}
              step={errorFields?.saml_x509_certs?.step}
            />
          </div>
        );
      default:
        return null;
    }
  };

  if (errors && errorFields) {
    const fields = Object.keys(errorFields) as (keyof SSOErrors)[];

    const fieldComponents: ReactNode[] = fields.map((field) => {
      if (errors[field]) {
        return getFieldComponent(field);
      }
      return null;
    });

    return (
      <Article>
        <Title>Step {query.step}: Confirmation</Title>

        {errorCount > 0 && (
          <div className="text-red-600">
            <Text inheritColor as="p" className="mt-8 mb-6" size="large">
              Please correct {errorCount} error
              {errorCount > 1 ? 's' : ''} and resubmit to complete your
              connection.
            </Text>
          </div>
        )}

        <div className="grid grid-cols-1 gap-4">
          {fieldComponents}
          <Confirm
            buttonText="Complete My Connection"
            disabled={errorCount > 0}
            isLoading={isLoading}
            label="Click here to finalize your Connection."
            onClick={completeConnection}
          />
        </div>
      </Article>
    );
  }

  return (
    <Article className="text-center flex flex-col items-center">
      <motion.i
        animate={{ opacity: 1, scale: 1 }}
        className="rounded-full w-[100px] h-[100px] bg-green-600 text-white flex items-center justify-center leading-[100px] my-10"
        initial={{ opacity: 0, scale: 0.5 }}
        transition={{ delay: 0.1 }}
      >
        <Check size={54} strokeWidth={2} />
      </motion.i>

      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ delay: 0.15 }}
      >
        <Title>SSO Configuration Complete</Title>
      </motion.div>

      <motion.div
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        transition={{ delay: 0.2 }}
      >
        <Text>Your users can now sign-in with {providerLabel}.</Text>
      </motion.div>

      <motion.div
        animate={{ opacity: 1, scale: 1 }}
        data-testid="configuration-complete"
        initial={{ opacity: 0, scale: 0.5 }}
        transition={{ delay: 0.25 }}
      >
        <Button onClick={() => connection && testConnection(connection.id)}>
          Test Single Sign-On
        </Button>
      </motion.div>
    </Article>
  );
};

const StepButton = ({
  setActiveStep,
  step,
}: {
  setActiveStep: (step: number) => void;
  step?: number;
}) => (
  <Button
    appearance="minimal"
    onClick={() => setActiveStep(step ?? 1)}
  >{`<- Show all of Step ${step}`}</Button>
);
